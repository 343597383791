<template>
  <div>
    <div>
      <b-form-checkbox v-model="checked" name="Language" switch>
        Switch Language {{ checked ? '(Germany)' : '(English)' }}</b-form-checkbox
      >
      <div style="display: flex; flex-direction: row; margin-top: 10px; margin-bottom: 10px">
        <div style="flex: 50%; padding: 10px">
          <b-form-input v-model="title" placeholder="Title"></b-form-input>
        </div>
        <div style="flex: 50%; padding: 10px">
          <b-form-input v-model="disclaimerType.icon" placeholder="Icon"></b-form-input>
          <a href="https://fonts.google.com/icons" target="_blank">https://fonts.google.com/icons</a>
        </div>
      </div>
    </div>
    <b-card footer-tag="footer" footer-class="text-right">
      <b-row>
        <b-col xl="12" lg="12" md="12" sm="12" cols="12"> <ckeditor v-model="editorData"></ckeditor></b-col>
      </b-row>

      <template #footer>
        <b-button variant="primary" class="font-weight-bold px-10" @click="add()"> Save </b-button>
      </template>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CKEditor from 'ckeditor4-vue'
import DisclaimerServices from '../../../core/services/disclaimers'

export default {
  name: 'disclaimers-data-protection',
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Disclaimers' }])
  },
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      show: 'dataProtection',
      editorData: '',
      title: '',
      checked: false,
      data: [],
      disclaimerId: this.id,
      disclaimerType: {
        titleEN: '',
        titleDE: '',
        htmlCodeEN: '',
        htmlCodeDE: '',
        icon: '',
      },
    }
  },
  methods: {
    async add() {
      console.log(`this.disclaimerType`, this.disclaimerType)

      const { data: responseData } = await DisclaimerServices.addDisclaimers(this.disclaimerType)

      console.log(`responseData`, responseData)
    },
  },
  watch: {
    checked: function (newVal, oldVal) {
      console.log(`oldVal`, oldVal)
      console.log(`newVal`, newVal)
      if (newVal) {
        // Germany
        return (this.editorData = this.disclaimerType.htmlCodeDE), (this.title = this.disclaimerType.titleDE)
      }
      this.title = this.disclaimerType.titleEN
      this.editorData = this.disclaimerType.htmlCodeEN
      //   if (newVal) {
      //     return (this.editorData = this.data.htmlCodeDE), (this.title = this.data.titleDE)
      //   }
      //   this.editorData = this.data.htmlCodeEN
      //   this.title = this.data.titleEN
    },
    editorData: function (newVal) {
      console.log(`editorData newVal`, newVal)
      if (this.checked) {
        // Germany
        this.disclaimerType.htmlCodeDE = newVal
        this.disclaimerType.titleDE = this.title
        return true
      }
      this.disclaimerType.htmlCodeEN = newVal
      this.disclaimerType.titleEN = this.title
    },
  },
}
</script>
