import ApiService from '../api.service'
const endPoint = '/infos'

const { get, post, put } = ApiService

class DisclaimerServices {
  async getAll() {
    return await get(endPoint)
  }

  async get(id) {
    return await get(`${endPoint}/${id}`)
  }

  async addDisclaimers(data) {
    return await post(endPoint, data)
  }

  async updateDisclaimers(id, data) {
    return await put(`${endPoint}/${id}`, { data })
  }
}

export default new DisclaimerServices()
